@import '../../colors';

.header {
    background-color: $background;
    opacity: 0.95;
}

.brand {
    display: flex;
    align-items: center;
}

.logo {
    font-size: 3.5rem;
    font-weight: 600;
    padding-left: 10px;
    color: $primary;
}

.icon {
    height: 4rem;
}

@media only screen and (max-width: 767px) {
    .logo {
        font-size: 6vw;
    }

    .icon {
        height: 8vw;
    }
}